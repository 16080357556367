/* eslint-disable react/no-danger */
import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

// import "@stylesPages/Platform.scss";
import "../styles/pages/Platform.scss";

// import Desktop from "@images/CpPlatform.png";
// import Graphics from "@icons/Graphics.svg";
// import Price from "@icons/Price.svg";
// import RealTime from "@icons/RealTime.svg";
// import Managment from "@icons/Managment.svg";

// import { Footer, Header, SEO } from "@components";
import Desktop from "../assets/images/CpPlatform.png";
import Graphics from "../assets/images/icons/Graphics.svg";
import Price from "../assets/images/icons/Price.svg";
import RealTime from "../assets/images/icons/RealTime.svg";
import Managment from "../assets/images/icons/Managment.svg";

import { Footer, Header, SEO } from "../components";

const Platform = () => {
  const { t } = useTranslation("platform");

  return (
    <div className="platform">
      <Header />
      <section className="platform__section platform__section--top">
        <div className="platform__top-left">
          <h1 className="platform__top-title">{t("title")}</h1>
          <h2 className="platform__top-copy">{t("subtitle")}</h2>
          <p
            className="platform__top-text"
            dangerouslySetInnerHTML={{ __html: t("text-header") }}
          />
        </div>
        <div className="platform__top-right">
          <div className="platform__top-img-content">
            <img src={Desktop} alt="" className="platform__top-img" />
          </div>
        </div>
      </section>
      <section className="platform__section platform__section--column">
        <div className="platform__cards">
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-1")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-1")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-2")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-2")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-3")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-3")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-4")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-4")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-5")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-5")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-6")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-6")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-7")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-7")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box">
            <div className="platform__cards-content">
              <h1 className="platform__title-paragraph">{t("title-paragraph-8")}</h1>
              <p className="platform__text platform__text--paragraph">
                {t("text-paragraph-8")}
              </p>
            </div>
          </article>
          <span className="platform__cards-box platform__cards-box--noborder" />
        </div>
      </section>
      <div className="platform__banner">
        <h2 className="platform__banner-title">{t("title-banner")}</h2>
        <p className="platform__text platform__text--banner">{t("text-banner")}</p>
      </div>
      <section className="platform__section platform__section--column">
        <div className="platform__cards platform__cards--icon">
          <article className="platform__cards-box platform__cards-box--icon">
            <img src={Graphics} alt="" className="platform__cards-box-icon" />
            <div className="platform__content-paragraph-icon">
              <h1 className="platform__title-icon">{t("title-icon-1")}</h1>
              <p className="platform__text platform__text--icon">
                {t("text-icon-1")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box platform__cards-box--icon">
            <img src={Managment} alt="" className="platform__cards-box-icon" />
            <div className="platform__content-paragraph-icon">
              <h1 className="platform__title-icon">{t("title-icon-2")}</h1>
              <p className="platform__text platform__text--icon">
                {t("text-icon-2")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box platform__cards-box--icon">
            <img src={RealTime} alt="" className="platform__cards-box-icon" />
            <div className="platform__content-paragraph-icon">
              <h1 className="platform__title-icon">{t("title-icon-3")}</h1>
              <p className="platform__text platform__text--icon">
                {t("text-icon-3")}
              </p>
            </div>
          </article>
          <article className="platform__cards-box platform__cards-box--icon">
            <img src={Price} alt="" className="platform__cards-box-icon" />
            <div className="platform__content-paragraph-icon">
              <h1 className="platform__title-icon">{t("title-icon-4")}</h1>
              <p className="platform__text platform__text--icon">
                {t("text-icon-4")}
              </p>
            </div>
          </article>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Platform;

export const Head = ({ data: { locales } }) => {
  const about = locales.edges.find(({ node }) => (node.ns === "platform"));

  const { "title": titleSeo } = about ? JSON.parse(about.node.data) : {
    "title": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;